import { get, put,Delete,post } from '../axios'

// 售后列表
export const list = params => get(`/order/web/refund/pageList`,params);
// 审核售后
export const refundAudit = params => post(`/order/web/refund/audit`,params);

// 售后详情
export const refundDetails = params => get(`/order/web/refund/detail/${params}`);

// 售后原因列表
export const afterPageList = params => get(`/order/web/refundReason/pageList`,params);
// 确认收货
export const refundConfirm = params => post(`/order/web/refund/confirm`,params);
// 商户发货
export const refundShipping = params => post(`/order/web/refund/shipping`,params);

// 供应商收货地址
export const getAddress = params => get(`/user/web/supplierAddress/get`,params);
// 保存供应商地址
export const saveAddress = params => post(`/user/web/supplierAddress/save`,params);
// 保存供应商地址
export const getRegionList = params => get(`/user/web/region/list`,params);

// 选择供应商收货地址
export const getSupplierAddress = params => get(`/user/web/supplierAddress/choose/${params}`);


// 选择供应商收货地址
export const getSupplierAddressList = params => get(`/user/web/supplierAddress/list`,params);

// 删除供应商收货地址
export const supplierAddressDel = params => Delete(`/user/web/supplierAddress/${params}`);

// 手动退款
export const returnReturn = params => post(`/order/web/refund/refund`,params);
// 修改售后类型
export const refundUpdateType= params => post(`/order/web/refund/updateType`,params);

// 计算售后
export const refundCalculate= params => post(`/order/web/refund/calculate`,params);
// 提交售后
export const refundSubmit= params => post(`/order/web/refund/submit`,params);




 

