import { get, put,Delete,post } from '../axios'

// 供应商订单列表
export const list = params => get(`/order/web/orders/subPageList`,params);
// 供应商订单详情
export const view = params => get(`/order/web/orders/subDetail`,params);
// 供应商订单发货
export const shipping = params => post(`/order/web/orders/shipping`,params);
// 快递公司-列表
export const expressList = params => get(`/order/web/expressCompany/list`,params);
// 物流轨迹查询
export const expressLogistics = params => get(`/order/web/expressCompany/logistics`,params);
// 订单数
export const ordersNum = params => get(`/order/web/orders/subCount`,params);
// 获取发货信息
export const shippingDetail = params => get(`/order/web/orders/shippingDetail`,params);

//订单发货-物流信息修改P1-xuwei
export const shippingUp = params => post(`/order/web/orders/shipping/update`,params);
 

 