export const state = {
    '0': '待支付',
    '10': '待发货',
    '11': '部分发货',
    '20': '待收货',
    '30': '待评价',
    '40': '已完成',
    '90': '已关闭',
    '100': '已取消',
}

export const afterState = {
    '0': '待审核',
    '1': 'E3审核',
    '10': '待用户退货',
    '20': '商家待收货',
    '21': '商家待发货',
    '30': '退款中',
    '40': '用户待收货',
    '50': '已完成',
    '90': '已关闭',
    '91': '商家拒绝',
}

export const payTypes= {
    1: '微信支付',
    2: '支付宝支付',
    3: '积分支付',
    4: '余额支付',
    5: '积分+线上支付',
    6: '余额+线上支付',
    7: '余额+积分支付',
    8: '积分+余额+线上支付',
    9: '免支付'
} 